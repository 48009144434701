import {
    Container,
    DatePicker,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
    Spinner,
} from '@amzn/awsui-components-react-v3';
import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
    DATE_PICKER_DISPLAY_FORMAT,
    getSelectedOptionValue,
    unixTimestampToDatePickerValue,
    valuesToOptions,
} from '../../../../../../common/utils/cloudscapeHelpers';
import { ActivityGroupMode } from '../../../../../interfaces/activityGroup';
import { activityGroupSlice } from '../../../../../store/slices/activityGroupSlice';
import { ActivityStatus } from '../../../Common/Common';
import {
    useActivityDetailsFormOptions,
    useActivityGroup,
} from '../../hooks/hooks';
import { AciMessage, AciResouceConstrain } from '../../utils/validations';

const SECONDS_PER_DAY = 86400;
const DAYS_PER_YEAR = 365;
const MAX_ACTIVITY_DURATION_YEAR = AciResouceConstrain.MAX_LAG_DURATION_YEAR;

const MAX_ACTIVITY_GROUP_DURATION_SEC =
    MAX_ACTIVITY_DURATION_YEAR * SECONDS_PER_DAY * DAYS_PER_YEAR;

const ActivityGroupEditDetailsForm = (props: { mode: ActivityGroupMode }) => {
    const dispatch = useDispatch();
    const activityGroup = useActivityGroup();
    const [formOptions, formOptionsLoading, coursesLoading] =
        useActivityDetailsFormOptions();
    const currentStatusValueRef = useRef(activityGroup.status);

    const { activityStatuses, courseOptions, programs, timezones } =
        formOptions;
    const existingGroupMode = ['EDIT'].includes(props.mode);

    const courseSelectOptions = courseOptions.map((course) => ({
        value: JSON.stringify(course),
        label: course.courseName,
    }));

    const selectedCourse = useMemo(() => {
        return courseOptions.find(
            (course) => course.catalogId === activityGroup.catalog_item_id,
        );
    }, [activityGroup.catalog_item_id, courseOptions]);

    const vaildateActivityGroupDateRange = (
        endDate: number,
        startDate: number,
    ): [boolean, string] => {
        let isValidActivityGroupDuration: boolean, invalidMessage: string;
        if (startDate > endDate) {
            isValidActivityGroupDuration = false;
            invalidMessage = AciMessage.INVALID_LAG_DATE;
        }
        if (endDate - startDate > MAX_ACTIVITY_GROUP_DURATION_SEC) {
            isValidActivityGroupDuration = false;
            invalidMessage = AciMessage.MAX_LAG_DURATION_YEAR;
        }
        return [isValidActivityGroupDuration, invalidMessage];
    };

    return (
        <Container header={<Header>Activity group details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Activity group name">
                    {!existingGroupMode ? (
                        <Input
                            value={activityGroup.name}
                            onChange={(e) => {
                                dispatch(
                                    activityGroupSlice.actions.setActivityGroup(
                                        {
                                            ...activityGroup,
                                            name: e.detail.value,
                                        },
                                    ),
                                );
                            }}
                            placeholder="Enter Activity Group Name"
                            data-testid="activity-group-name-input"
                        />
                    ) : (
                        activityGroup.name
                    )}
                </FormField>
                <FormField label="Program">
                    {!existingGroupMode ? (
                        <Select
                            options={valuesToOptions(programs)}
                            selectedOption={getSelectedOptionValue(
                                activityGroup.program_name,
                            )}
                            placeholder="Select Program"
                            empty="User is not assigned to any programs that support activity groups"
                            filteringType="auto"
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityGroup(
                                        {
                                            ...activityGroup,
                                            program_name:
                                                e.detail.selectedOption.value,
                                        },
                                    ),
                                )
                            }
                            data-testid="program-select"
                        />
                    ) : (
                        activityGroup.program_name
                    )}
                </FormField>
                <FormField label="Course">
                    {!existingGroupMode ? (
                        <Select
                            options={
                                formOptionsLoading || coursesLoading
                                    ? []
                                    : courseSelectOptions
                            }
                            selectedOption={
                                formOptionsLoading ||
                                coursesLoading ||
                                !selectedCourse
                                    ? null
                                    : getSelectedOptionValue(
                                          selectedCourse.courseName,
                                      )
                            }
                            statusType={
                                formOptionsLoading || coursesLoading
                                    ? 'loading'
                                    : 'finished'
                            }
                            empty="Selected program has no courses supported by Activity Groups"
                            loadingText="Loading courses for selected program"
                            placeholder={
                                formOptionsLoading || coursesLoading
                                    ? 'Loading courses...'
                                    : 'Select Course'
                            }
                            onChange={(e) => {
                                const selectedCourse = JSON.parse(
                                    e.detail.selectedOption.value,
                                );

                                dispatch(
                                    activityGroupSlice.actions.setCourse({
                                        course_name: selectedCourse.courseName,
                                        catalog_item_versioned_id:
                                            selectedCourse.catalogVersionedId,
                                        catalog_item_id:
                                            selectedCourse.catalogId,
                                    }),
                                );
                            }}
                            filteringType="auto"
                            data-testid="course-select"
                        />
                    ) : (
                        <p>
                            {selectedCourse ? (
                                selectedCourse.courseName
                            ) : (
                                <Spinner data-testid="ActivityGroupEditDetailsFormSpinner" />
                            )}
                        </p>
                    )}
                </FormField>
                <FormField label="Status">
                    <Select
                        options={valuesToOptions(activityStatuses)}
                        selectedOption={{
                            value: activityGroup.status || 'Hold',
                            label: activityGroup.status || 'Hold',
                        }}
                        disabled={!(props.mode === 'EDIT')}
                        placeholder="Select Status"
                        onChange={(e) => {
                            const selectedValue = e.detail.selectedOption.value;
                            if (
                                selectedValue === currentStatusValueRef.current
                            ) {
                                return;
                            }
                            currentStatusValueRef.current =
                                selectedValue as ActivityStatus;
                            dispatch(
                                activityGroupSlice.actions.setActivityGroupStatus(
                                    selectedValue as any,
                                ),
                            );
                        }}
                        data-testid="status-select"
                    ></Select>
                </FormField>
                <FormField label="Time zone">
                    <Select
                        options={valuesToOptions(timezones)}
                        selectedOption={getSelectedOptionValue(
                            activityGroup.selected_timezone,
                        )}
                        placeholder="Select a Time Zone"
                        onChange={(e) =>
                            dispatch(
                                activityGroupSlice.actions.setActivityGroup({
                                    ...activityGroup,
                                    selected_timezone:
                                        e.detail.selectedOption.value,
                                }),
                            )
                        }
                        filteringType="auto"
                        data-testid="timezone-select"
                    ></Select>
                </FormField>
                <SpaceBetween size="l" direction="horizontal">
                    <FormField label="Start Date">
                        <DatePicker
                            value={unixTimestampToDatePickerValue(
                                activityGroup.start_timestamp,
                                activityGroup.selected_timezone,
                            )}
                            placeholder={DATE_PICKER_DISPLAY_FORMAT}
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setStartTimeStampFromDatePickerValue(
                                        {
                                            value: e.detail.value,
                                            selectedTimezone:
                                                activityGroup.selected_timezone,
                                        },
                                    ),
                                )
                            }
                            data-testid="start-date-picker"
                        />
                    </FormField>
                    <FormField
                        label="End Date"
                        errorText={(() => {
                            const [isValid, message] =
                                vaildateActivityGroupDateRange(
                                    activityGroup.end_timestamp,
                                    activityGroup.start_timestamp,
                                );
                            return !isValid ? message : undefined;
                        })()}
                    >
                        <DatePicker
                            value={unixTimestampToDatePickerValue(
                                activityGroup.end_timestamp,
                                activityGroup.selected_timezone,
                            )}
                            placeholder={DATE_PICKER_DISPLAY_FORMAT}
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setEndTimeStampFromDatePickerValue(
                                        {
                                            value: e.detail.value,
                                            selectedTimezone:
                                                activityGroup.selected_timezone,
                                        },
                                    ),
                                )
                            }
                            data-testid="end-date-picker"
                        />
                    </FormField>
                </SpaceBetween>
            </SpaceBetween>
        </Container>
    );
};

export default ActivityGroupEditDetailsForm;
