import { Spinner } from '@amzn/awsui-components-react';
import { SectionContentType } from '../../../../../../common/constants/grimsby';
import { formatString } from '../../../../../../common/utils/formatString';
import { StatusIndicator } from '@amzn/awsui-components-react-v3';
import { getStatusIndicatorType } from '../../utils/activity-group-utils';
import { formatGrimsbyDate } from '../../../Common/Common';

export const activityGroupDetailsColumnDefinition = ({
    coursesLoading,
    courseOptions,
    activityGroup,
}) => {
    const selectedCourseBasedOnCatalogId =
        !coursesLoading &&
        courseOptions.find(
            (course) => course.catalogId === activityGroup.catalog_item_id,
        );
    return {
        type: SectionContentType.Column,
        columnsCount: 2,
        columns: [
            [
                {
                    key: 'Activity Group Name',
                    value: formatString(activityGroup.name),
                },
                {
                    key: 'Program',
                    value: formatString(activityGroup.program_name),
                },
                {
                    key: 'Course',
                    value: coursesLoading ? (
                        <Spinner data-testid="CourseSpinner" />
                    ) : (
                        formatString(selectedCourseBasedOnCatalogId?.courseName)
                    ),
                },
                {
                    key: 'Status',
                    value: (
                        <StatusIndicator
                            type={getStatusIndicatorType(activityGroup.status)}
                        >
                            {activityGroup.status}
                        </StatusIndicator>
                    ),
                },
            ],
            [
                {
                    key: 'Time zone',
                    value: formatString(activityGroup.selected_timezone),
                },
                {
                    key: 'Start Date',
                    value: formatGrimsbyDate(
                        activityGroup.start_timestamp,
                        activityGroup.selected_timezone,
                    ),
                },
                {
                    key: 'End Date',
                    value: formatGrimsbyDate(
                        activityGroup.end_timestamp,
                        activityGroup.selected_timezone,
                    ),
                },
            ],
        ],
    };
};
